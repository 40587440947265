@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/* BOLD */

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-Black.ttf") format("truetype");
    font-weight: 900;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-Bold.ttf") format("truetype");
    font-weight: 700;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-Light.ttf") format("truetype");
    font-weight: 200;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-Regular.ttf") format("truetype");
    font-weight: 300;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-Medium.ttf") format("truetype");
    font-weight: 400;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "promt";
    src: url("../assets/fonts/Prompt/Prompt-SemiBold.ttf") format("truetype");
    font-weight: 600;
    /* font-style: normal; */
    font-display: block;
}

body {
    color: #2c2c2c;
    font-family: "promt", sans-serif;
    background-color: #f4f5f8;
}

.navigation-account {
    font-family: 'Fira Sans', sans-serif;
}

.text-xxs {
    font-size: 10px;
}

.text-xxxs {
    font-size: 8px;
}

.text-xs {
    font-size: 12px;
}

.text-sm {
    font-size: 14px;
}

.text-base {
    font-size: 16px;
}

.text-lg {
    font-size: 18px;
}

.text-xl {
    font-size: 20px;
}

.text-2xl {
    font-size: 22px;
}

.text-3xl {
    font-size: 24px;
}

.text-4xl {
    font-size: 26px;
}

.text-5xl {
    font-size: 28px;
}

.text-6xl {
    font-size: 30px;
}

.text-7xl {
    font-size: 40px;
}

.text-8xl {
    font-size: 60px;
}

.text-9xl {
    font-size: 80px;
}


/*animation header*/

@keyframes sliderMenu {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.header-animation {
    animation: sliderMenu 0.5s ease-in-out;
}

@keyframes sliderMenuBack {
    0% {
        transform: translateX(0);
        width: 50%;
        height: 100vh;
        position: fixed;
        z-index: 50;
    }
    100% {
        transform: translateX(-100%);
    }
}

.header-animation-back {
    animation: sliderMenuBack 0.5s ease-in-out;
}

.fakeMenu {
    display: none;
}

#CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
}


/*loader*/

.spinner {
    width: 70px;
    text-align: center;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}